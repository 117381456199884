import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import Image from "../components/tacticalImg"
import Hero from "../components/hero"
import Cta from "../components/cta"

const SoinsCorpStyling = styled.div`
  .grid {
    margin-bottom: var(--medium);
  }
  @media (max-width: 640px) {
    .grid {
      grid-row-gap: var(--medium);
    }
  }
`

const Services = ({ setShowCta, cta }) => (
  <Layout>
    <SEO title="Types de Massothérapie" />
    <Hero title="Types de Massothérapie" hero="types-massotherapie.jpg" />
    <SoinsCorpStyling className="container highlow">
      <div className="grid">
        {/* Massage Classique */}
        <div className="col-md-6 vertical-center make-room-medium">
          <Image
            src="massotherapie-1.jpg"
            alt="Massage Classique - massage Suédois"
          />
        </div>
        <div
          className="col-md-6 vertical-center"
          style={{ padding: "0 var(--large)" }}
        >
          <h2 style={{ textAlign: "left" }}>
            Massage Classique - massage Suédois
          </h2>
          <p>
            Le massage classique est aussi appelé «massage suédois» car les
            bases de cette technique ont été élaborées au début du siècle
            dernier par le Suédois Henri Peter Ling. Il aide le corps à
            retrouver son équilibre naturel.
          </p>
          <p>
            Une aide merveilleuse en cas de douleurs et de contractions.
            Revigore et harmonise le corps et l'esprit. Le massage classique
            possède de multiples actions thérapeutiques. Il permet de renforcer
            le système immunitaire, d’améliorer les fonctions digestives, de
            stimuler la vitalité des tissus, favori-ser une bonne circulation
            sanguine et d’apaiser les tensions nerveuses. Le massage relaxant
            est recommandé en cas d’insomnies, d’angoisses, de stress, de
            nervosité, de constipation ou encore de migraines.
          </p>
          <p>
            <strong>Contre-indications:</strong>
            <br />
            Toutes formes d’inflammations, toutes maladies de peau et tous
            problèmes circulatoires.
          </p>
        </div>
      </div>
      <div className="grid">
        {/* Massage Sportif */}
        <div
          className="col-md-6 vertical-center"
          style={{ padding: "0 var(--large)" }}
        >
          <h2 style={{ textAlign: "left" }}>Massage Sportif</h2>
          <p>
            Contrairement à ce que son nom indique, il n’est pas nécessaire
            d’être un athlète pour recevoir ce type de massage. Il n’est pas
            fait pour la relaxation, mais peut tout de même apporter une
            sensation de détente.
          </p>
          <p>
            Après un effort sportif, ce massage énergique et profond améliore la
            circulation sanguine et lymphatique. Il permet une élimination plus
            rapide des toxines et favorise la régénéra-tion des tissus. La
            période de récupération est ainsi optimisée et la reprise des
            entraîne-ments peut se faire plus rapidement.
          </p>
          <p>
            Ce massage atténue les raideurs, courbatures ou contractures
            musculaires consécutives aux efforts intenses. Idéalement, il se
            combine avec un programme d’entraînement régu-lier et participe
            ainsi à la prévention des blessures et à l’amélioration du rendement
            spor-tif.
          </p>
          <p>
            Un choix spécifique d’huiles essentielles associées à l’huile
            végétale renforce les effets antalgiques et anti-inflammatoires de
            ce massage.
          </p>
        </div>
        <div className="col-md-6 vertical-center make-room-medium mobile-first">
          <Image src="massotherapie-2.jpg" alt="Massage Sportif" />
        </div>
      </div>
      <div className="grid">
        {/* Massages thérapeutiques, empirique */}
        <div className="col-md-6 vertical-center make-room-medium">
          <Image
            src="massotherapie-3.jpg"
            alt="Massages thérapeutiques, empirique"
          />
        </div>
        <div
          className="col-md-6 vertical-center"
          style={{ padding: "0 var(--large)" }}
        >
          <h2 style={{ textAlign: "left" }}>
            Massages thérapeutiques, empirique
          </h2>
          <p>
            Technique de thérapie manuelle des muscles profonds et des fascias,
            aidant à réduire les contractures et les hypertensions musculaires,
            qui sont souvent à l’origine des douleurs chroniques. Permet de
            diminuer la pression artérielle, de relaxer les muscles ,
            d’améliorer la souplesse et la mobilité des articulations.Il a pour
            but de traiter les douleurs et tensions musculaires et tendineuses.
          </p>
        </div>
      </div>
      <div className="grid">
        {/* Point Cachette (Trigger-points) */}
        <div
          className="col-md-6 vertical-center"
          style={{ padding: "0 var(--large)" }}
        >
          <h2 style={{ textAlign: "left" }}>Massage Points Gâchette</h2>
          <p>
            Les points déclencheurs sont des points de tension à l’intérieur du
            tissu musculaire , qui se forment pour différentes raisons : effet
            secondaire de certains médicaments, accident, mauvaise posture,
            mouvements répétitifs ou stress.
          </p>
          <p>
            Ces points sensibles à la palpation peuvent entraîner la contracture
            du muscle tout entier et cela provoque de fortes douleurs. Les
            douleurs sont situées souvent à une certaine distance du point
            gâchette. Pour éliminer les points déclencheurs, on exerce une
            pression assez forte sur ces zones. Cette méthode est très efficace
            et peut aider à éliminer les douleurs articulaires, les tensions,
            les douleurs musculaires et améliorer la capacité des mouvements.
            Les points déclencheurs furent un critère pour diagnostiquer la
            fibromyalgie après avoir vérifié et éliminé d’autres possibilités de
            mala-dies.
          </p>
        </div>
        <div className="col-md-6 vertical-center mobile-first make-room-medium">
          <Image
            src="massotherapie-4.jpg"
            alt="Point Cachette (Trigger-points)"
          />
        </div>
      </div>
      <div className="grid">
        {/* Stretching thérapeutique */}
        <div className="col-md-6 vertical-center make-room-medium">
          <Image src="massotherapie-5.jpg" alt="Stretching thérapeutique" />
        </div>
        <div
          className="col-md-6 vertical-center"
          style={{ padding: "0 var(--large)" }}
        >
          <h2 style={{ textAlign: "left" }}>Stretching thérapeutique</h2>
          <p>
            Le stretching thérapeutique permet de diminuer les tensions
            musculaires, d’augmenter la souplesse et l’élasticité, l’amplitude
            du mouvement et la circulation sanguine. Il stimule la conscience
            des muscles, de la posture et surtout il améliore l’intégration d’un
            traitement thé-rapeutique des muscles.
          </p>
        </div>
      </div>
      <div className="grid">
        {/* Acupressure */}
        <div
          className="col-md-6 vertical-center"
          style={{ padding: "0 var(--large)" }}
        >
          <h2 style={{ textAlign: "left" }}>Acupressure</h2>
          <p>
            Pour le rééquilibrage et la circulation des énergies. Technique de
            thérapie ma-nuelle d’origine asiatique utilisant la pression des
            doigts sur différentes parties du corps hu-main. Elle utilise des
            points situés le long des méridiens. La pression de ces points sert
            à fluidifier la circulation des énergies.
          </p>
        </div>
        <div className="col-md-6 vertical-center mobile-first make-room-medium">
          <Image src="massotherapie-6.jpg" alt="Acupressure" />
        </div>
      </div>
      <div className="grid">
        {/* Massage relaxant aux pierres chaudes  */}
        <div className="col-md-6 vertical-center make-room-medium">
          <Image
            src="massotherapie-7.jpg"
            alt="Massage relaxant aux pierres chaudes "
          />
        </div>
        <div
          className="col-md-6 vertical-center"
          style={{ padding: "0 var(--large)" }}
        >
          <h2 style={{ textAlign: "left" }}>
            Massage relaxant aux pierres chaudes
          </h2>
          <p>
            Cette technique amérindienne est thérapeutique et énergétique. Elle
            stimule les corps physiques, men-taux et spirituels, et apporte la
            paix intérieure. C’est une expérience sensorielle unique.
          </p>
          <p>
            Le massage aux pierres chaudes, est un massage de détente,
            rééquilibrant et harmonisant. La circula-tion des énergies vitales,
            de la lymphe et du flux sanguin augmente. L’élimination des toxines
            et des déchets sera favorisée.
          </p>
          <p>
            Il est également indiqué:
            <ul className="services">
              <li>Douleurs dorsales</li>
              <li>Problèmes circulatoires</li>
              <li>Ostéoarthrite ou problèmes d'arthrite</li>
              <li>Stress, anxiété et tension</li>
              <li>Insomnie</li>
              <li>Dépression</li>
            </ul>
          </p>
          <p>
            <strong>Conseils: </strong>Favorisez le choix d’un autre massage si
            vous souffrez de varices, de troubles veineux ou circula-toires.
          </p>
        </div>
      </div>
      <div className="grid">
        {/* Massage à la Bougie */}
        <div
          className="col-md-6 vertical-center"
          style={{ padding: "0 var(--large)" }}
        >
          <h2 style={{ textAlign: "left" }}>Massage à la Bougie</h2>
          <p>
            <strong>Grande sensation de bien-être!</strong>
          </p>
          <p>
            Bien-être et relaxation sont les mots d'ordre de ce massage. Des
            gestes lents, doux et apaisants vous transporteront vers d'autres
            horizons. Laissez l'huile chaude faire son effet et lâchez prise!
          </p>
        </div>
        <div className="col-md-6 vertical-center mobile-first make-room-medium">
          <Image src="massotherapie-8.jpg" alt="Massage à la Bougie" />
        </div>
      </div>
      <div className="grid">
        {/* Massage Prénatal */}
        <div className="col-md-6 vertical-center make-room-medium">
          <Image src="massotherapie-9.jpg" alt="Massage Prénatal" />
        </div>
        <div
          className="col-md-6 vertical-center"
          style={{ padding: "0 var(--large)" }}
        >
          <h2 style={{ textAlign: "left" }}>Massage Prénatal</h2>
          <p>
            Le massage prénatal va permettre de soulager les tensions
            musculaires et les douleurs dor-sales. D’améliorer la circulation
            sanguine, d’agir ainsi sur la rétention d’eau, les jambes lourdes et
            de réduire les troubles du sommeil. Il assouplit la peau en
            diminuant l’apparition de vergetures.
          </p>
          <p>
            Les massages peuvent s’effectuer à partir du troisième mois de
            grossesse et jusqu'à l'ac-couchement (sauf contre-indications
            médicales).
          </p>
        </div>
      </div>
      <div className="grid">
        {/* Réflexologie */}
        <div
          className="col-md-6 vertical-center"
          style={{ padding: "0 var(--large)" }}
        >
          <h2 style={{ textAlign: "left" }}>Réflexologie</h2>
          <p>
            Les points réflexes utilisés se retrouvent sur les pieds (chaque
            point est lié à une partie du corps).
          </p>
          <p>
            La technique d’acupression déclenche des mécanismes d’autorégulation
            et de détente pro-fonde.
          </p>
        </div>
        <div className="col-md-6 vertical-center mobile-first make-room-medium">
          <Image
            src="massotherapie-10.jpg"
            alt="Réflexologie générale et Orientale"
          />
        </div>
      </div>
      <div className="grid">
        {/* Massage assis */}
        <div className="col-md-6 vertical-center make-room-medium">
          <Image src="massotherapie-11.jpg" alt="Massage assis" />
        </div>
        <div
          className="col-md-6 vertical-center"
          style={{ padding: "0 var(--large)" }}
        >
          <h2 style={{ textAlign: "left" }}>Massage assis</h2>
          <p>
            Mélange d’acupression, d’étirement et de travail sur les tissus
            musculaires, ces séances favorisent la diminution du stress, des
            tensions physiques ou émotionnelles et améliorent la vitalité et la
            concentra-tion.
          </p>
          <p>
            Le massage assis se fait habillé, sur une chaise adaptée. Les zones
            traitées sont principalement le dos, les épaules, la nuque, les bras
            et les mains. Le massage assis est accessible à tous. Veuillez
            néanmoins m’informer si vous souffrez d’un problème de santé ou d’un
            manque de mobilité des articulations. En cas de grossesse, il vaut
            mieux éviter de re-cevoir ce type de massage.
          </p>
        </div>
      </div>
      <div className="grid">
        {/* Massage de Couple  */}
        <div
          className="col-md-6 vertical-center"
          style={{ padding: "0 var(--large)" }}
        >
          <h2 style={{ textAlign: "left" }}>Massage pour le Couple</h2>
          <p>
            Le rituel « Escapade cocooning en Duo » comprend 90 minutes de
            massage détente, bercées par une douce musique.
          </p>
        </div>
        <div className="col-md-6 vertical-center mobile-first make-room-medium">
          <Image src="massotherapie-12.jpg" alt="Massage de Couple " />
        </div>
        {/* Le massage anti-cellulite  */}
        <div className="col-md-6 vertical-center make-room-medium">
          <Image src="massotherapie-13.jpg" alt="Le massage anti-cellulite " />
        </div>
        <div
          className="col-md-6 vertical-center"
          style={{ padding: "0 var(--large)" }}
        >
          <h2 style={{ textAlign: "left" }}>Le massage anti-cellulite </h2>
          <p>
            Les causes de l’apparition de cellulite sont nombreuses. On peut
            citer les variations de poids suite à une grossesse, le manque
            d’exercice physique, la mauvaise circulation sanguine et
            lymphatique, le stress, les changements hormonaux, l’âge car plus on
            vieillit et plus la cellulite est susceptible de se dé-velopper mais
            aussi, et surtout, une mauvaise alimentation.
          </p>
        </div>
      </div>
      <div className="grid">
        {/* Drainage lymphatique  */}
        <div
          className="col-md-6 vertical-center"
          style={{ padding: "0 var(--large)" }}
        >
          <h2 style={{ textAlign: "left" }}>Drainage lymphatique</h2>
          <p>
            Il élimine les toxines, active la synthèse des protéines et augmente
            la force du système immunitaire. La pression de ce massage est
            douce, son action aussi bien préventive que thérapeutique.
          </p>
        </div>
        <div className="col-md-6 vertical-center mobile-first make-room-medium">
          <Image src="massotherapie-16.jpg" alt="Drainage lymphatique " />
        </div>
      </div>
      <div className="grid">
        {/* Massage pour les enfants et les bébés  */}
        <div className="col-md-6 vertical-center make-room-medium">
          <Image
            src="massotherapie-17.jpg"
            alt="Massage pour les enfants et les bébés "
          />
        </div>
        <div
          className="col-md-6 vertical-center"
          style={{ padding: "0 var(--large)" }}
        >
          <h2 style={{ textAlign: "left" }}>
            Massage pour les enfants et les bébés
          </h2>
          <p>
            L’ acupression est un toucher très doux sont efficaces pour le
            bien-être et le développement du bébé.
          </p>
        </div>
      </div>
    </SoinsCorpStyling>
    <Cta setShowCta={setShowCta} cta={cta} />
  </Layout>
)

export default Services
